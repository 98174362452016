import Vue from 'vue'
// import Login from './views/Login.vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
// 折线图
import VeLine from 'v-charts/lib/line.common'
// 饼图
import VePie from 'v-charts/lib/pie.common'
// 柱形图
import VeHistogram from 'v-charts/lib/histogram.common'
// 自定义样式
import './assets/mystyle.less'
// vue-canvas-poster
import VueCanvasPoster from 'vue-canvas-poster'
// vue-org-tree
// import Vue2OrgTree from 'vue2-org-tree'
import Vue2OrgTree from 'vue-tree-color'
// 右键菜单插件
import Contextmenu from "vue-contextmenujs"
// 自定义公共函数
import myjs from './assets/myjs.js'

import axios from './config/axiosTools.js'
// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Print from './assets/print'
import VueResource from 'vue-resource'
Vue.component(VeLine.name, VeLine)
Vue.component(VePie.name, VePie)
Vue.component(VeHistogram.name, VeHistogram)
Vue.use(ViewUI)
Vue.use(VueCanvasPoster)
Vue.use(Vue2OrgTree)
Vue.use(Contextmenu);
Vue.use(Print)
Vue.use(VueResource)

Vue.config.productionTip = false;
Vue.prototype.myjs = myjs;
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
})

new Vue({
  router,
  store,
  axios,
  // render: h => h(Login, App)
  render: h => h(App)
}).$mount('#app')
