import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 动态面包屑
const breadcrumb = {
	state: {
		bItems: ["首页"]
	},
	mutations: {
		changeBitems(state, value) {
			state.bItems = value
		},
		setBreadcrumb(state,setValue){
			let len = setValue.lenCtrl;
			state.bItems.length = len;
			state.bItems = state.bItems.concat(setValue.addValue);
		}
	},
	actions: {
		changeBitems({ commit },value) {
			commit('changeBitems',value)
		},
		setBreadcrumb({ commit },setValue){
			commit('setBreadcrumb',setValue)
		}
	}
}
export default new Vuex.Store({
	modules: {
		breadcrumb
	}
})
