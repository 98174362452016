import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from 'vue/types/umd'
// import Home from '@/views/Home.vue'
import cookies from 'js-cookie'
import layout from '../layout/index.vue'
Vue.use(VueRouter)

const routes = [
	// 
	// 登录的页面
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/Login'),
		hidden: true
	},
	// 主要的页面
	{
		path: '/',
		component: layout,
		redirect: '/Welcome',
		children: [{
			component: () => import('@/views/Welcome'),
			name: 'Welcome',
			path: 'Welcome',
			meta: {
				bItems: ["首页"]
			}
		}, {
			path: '/Users',
			name: 'Users',
			component: () => import('@/views/Users.vue'),
			meta: {
				bItems: ["用户管理", "个人用户"]
			}
		}, {
			path: '/AssoUser',
			name: 'AssoUser',
			component: () => import('@/views/AssoUser.vue'),
			meta: {
				bItems: ["用户管理", "个人用户", "关联用户"]
			}
		}, {
			path: '/Organ',
			name: 'Organ',
			component: () => import('@/views/Organ.vue'),
			meta: {
				bItems: ["用户管理", "单位用户"]
			}
		}, {
			path: '/Depart',
			name: 'Depart',
			component: () => import('@/views/Depart.vue'),
			meta: {
				bItems: ["用户管理", "单位用户", "组织管理"]
			}
		}, {
			path: '/Member',
			name: 'Member',
			component: () => import('@/views/Member.vue'),
			meta: {
				bItems: ["用户管理", "单位用户", "成员管理"]
			}
		}, {
			path: '/ScalesDossiers',
			name: 'ScalesDossiers',
			component: () => import('@/views/ScalesDossiers.vue'),
			meta: {
				bItems: ["测评管理", "测评档案"]
			}
		}, {
			path: '/Trend',
			name: 'Trend',
			component: () => import('@/views/Trend.vue'),
			meta: {
				bItems: ["测评管理", "趋势分析"]
			}
		}, {
			path: '/Group',
			name: 'Group',
			component: () => import('@/views/Group.vue'),
			meta: {
				bItems: ["测评管理", "团体分析"]
			}
		}, {
			path: '/ScaleTask',
			name: 'ScaleTask',
			component: () => import('@/views/ScaleTask.vue'),
			meta: {
				bItems: ["测评管理", "测评任务"]
			}
		}, {
			path: '/TaskDetails',
			name: 'TaskDetails',
			component: () => import('@/views/TaskDetails.vue'),
			meta: {
				bItems: ["测评管理", "测评任务"]
			}
		}, {
			path: '/Scales',
			name: 'Scales',
			component: () => import('@/views/Scales.vue'),
			meta: {
				bItems: ["测评管理", "量表设置"]
			}
		}, {
			path: '/ScalePackage',
			name: 'ScalePackage',
			component: () => import('@/views/ScalePackage.vue'),
			meta: {
				bItems: ["测评管理", "量表套餐"]
			}
		}, {
			path: '/Schedule',
			name: 'Schedule',
			component: () => import('@/views/Schedule.vue'),
			meta: {
				bItems: ["咨询管理", "排班管理"]
			}
		}, {
			path: '/Reserve',
			name: 'Reserve',
			component: () => import('@/views/Reserve.vue'),
			meta: {
				bItems: ["咨询管理", "预约管理"]
			}
		}, {
			path: '/ConsultCase',
			name: 'ConsultCase',
			component: () => import('@/views/ConsultCase.vue'),
			meta: {
				bItems: ["咨询管理", "咨询档案"]
			}
		}, {
			path: '/Counselor',
			name: 'Counselor',
			component: () => import('@/views/Counselor.vue'),
			meta: {
				bItems: ["咨询管理", "咨询师管理"]
			}
		}, {
			path: '/CounselorDetails',
			name: 'CounselorDetails',
			component: () => import('@/views/CounselorDetails.vue'),
			meta: {
				bItems: ["咨询管理", "从业者管理"]
			}
		}, {
			path: '/Room',
			name: 'Room',
			component: () => import('@/views/Room.vue'),
			meta: {
				bItems: ["咨询管理", "咨询室管理"]
			}
		}, {
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),

		}, {
			path: '/wx_pay',
			name: 'paysetting',
			component: () => import('@/views/wx_pay.vue'),
			meta: {
				bItems: ["微信管理", "小程序支付"]
			}
		}, {
			path: '/mpsetting',
			name: 'mpsetting',
			component: () => import('@/views/mpsetting.vue'),
			meta: {
				bItems: ["微信管理", "小程序设置"]
			}
		}, {
			path: '/mp_kf',
			name: 'openkf',
			component: () => import('@/views/mp_kf.vue'),
			meta: {
				bItems: ["微信管理", "小程序客服"]
			}
		}, {
			path: '/qrcode',
			name: 'qrcode',
			component: () => import('@/views/qrcode.vue'),
			meta: {
				bItems: ["微信管理", "生成小程序码"]
			}
		}, {
			path: '/ReleaseSurvey',
			name: 'ReleaseSurvey',
			component: () => import('@/views/ReleaseSurvey.vue'),
			meta: {
				bItems: ["问卷管理", "发布问卷"]
			}
		}, {
			path: '/SurveyData',
			name: 'SurveyData',
			component: () => import('@/views/SurveyData.vue'),
			meta: {
				bItems: ["问卷管理", "问卷数据"]
			}
		}, {
			path: '/SurveyDesign',
			name: 'SurveyDesign',
			component: () => import('@/views/SurveyDesign.vue'),
			meta: {
				bItems: ["问卷管理", "问卷设计"]
			}
		}, {
			path: '/ReleaseActivity',
			name: 'ReleaseActivity',
			component: () => import('@/views/ReleaseActivity.vue'),
			meta: {
				bItems: ["活动管理", "发布活动"]
			}
		}, {
			path: '/Apply',
			name: 'Apply',
			component: () => import('@/views/Apply.vue'),
			meta: {
				bItems: ["活动管理", "报名管理"]
			}
		}, {
			path: '/SignIn',
			name: 'SignIn',
			component: () => import('@/views/SignIn.vue'),
			meta: {
				bItems: ["活动管理", "签到管理"]
			}
		}, {
			path: '/ActivityDesign',
			name: 'ActivityDesign',
			component: () => import('@/views/ActivityDesign.vue'),
			meta: {
				bItems: ["活动管理", "活动设计"]
			}
		}, {
			path: '/Role',
			name: 'BaseRole',
			component: () => import('@/views/Role.vue'),
			meta: {
				bItems: ["权限管理"]
			}
		}, {
			path: '/Role',
			name: 'Role',
			component: () => import('@/views/Role.vue'),
			meta: {
				bItems: ["权限管理", "功能权限设置"]
			}
		}, {
			path: '/DataRole',
			name: 'DataRole',
			component: () => import('@/views/DataRole.vue'),
			meta: {
				bItems: ["权限管理", "数据权限设置"]
			}
		}, {
			path: '/userDetail',
			name: 'userDetail',
			component: () => import('@/views/userDetail.vue'),
			meta: {
				bItems: ["用户详情"]
			}
		}, {
			path: '/Orders',
			name: 'Orders',
			component: () => import('@/views/Orders.vue'),
			meta: {
				bItems: ["订单管理"]
			}
		}, {
			path: '/AddSurvey',
			name: 'AddSurvey',
			component: () => import('@/views/AddSurvey.vue'),
			meta: {
				bItems: ["添加问卷"]
			}
		}, {
			path: '/systemsetting',
			name: 'systemsetting',
			component: () => import('@/views/systemsetting.vue'),
			meta: {
				bItems: ["系统设置", "机构设置"]
			}
		}, {
			path: '/PrintSetting',
			name: 'PrintSetting',
			component: () => import('@/views/PrintSetting.vue'),
			meta: {
				bItems: ["系统设置", "报告设置"]
			}
		}, {
			path: '/Welcome',
			name: 'Welcome',
			component: () => import('@/views/Welcome.vue'),
			meta: {
				bItems: ["工作台", "首页"]
			}
		}, {
			path: '/Analysis',
			name: 'Analysis',
			component: () => import('@/views/Analysis.vue'),
			meta: {
				bItems: ["工作台", "数据分析"]
			}
		}, {
			path: '/Careerman',
			name: 'Careerman',
			component: () => import('@/views/Careerman.vue'),
			meta: {
				bItems: ["从业者管理", "专业人员管理"]
			}
		}, {
			path: '/report',
			name: 'report',
			component: () => import('@/views/report.vue'),
			meta: {
				bItems: ["测评数据", "查看报告"]
			}
		}, {
			path: '/Verdict',
			name: 'Verdict',
			component: () => import('@/views/Verdict.vue'),
			meta: {
				bItems: ["量表设置", "自定义结论"]
			}
		}, {
			path: '/ReleaseScaleTask',
			name: 'ReleaseScaleTask',
			component: () => import('@/views/ReleaseScaleTask.vue'),
			meta: {
				bItems: ["测评管理", "测评任务", "发布量表测评任务（个体任务）"]
			}
		}, {
			path: '/ReleaseGroupScaleTask',
			name: 'ReleaseGroupScaleTask',
			component: () => import('@/views/ReleaseGroupScaleTask.vue'),
			meta: {
				bItems: ["测评管理", "测评任务", "发布量表测评任务（团体任务）"]
			}
		},{
			path: '/UnitDetail',
			name: 'UnitDetail',
			component: () => import('@/views/UnitDetail.vue'),
			meta: {
				bItems: ["用户管理", "单位用户", "单位用户详情"]
			}
		},]
	},
]

const router = new VueRouter({
	routes,
	mode: 'hash',

})
// 白名单
let whiteSpace = ['/login'];


router.beforeEach((to, from, next) => {
	let key = cookies.get('token')
	// let key = localStorage.get('token')
	// 如果已经登录了 并且存储了token
	if (key) {
		// console.log(key)
		if (to.path == '/Welcome') {
			next()
		} else {
			if (whiteSpace.includes(to.path)) {
				next('/')
			} else {
				next()
			}
		}
	} else {
		// console.log(key)
		if (!whiteSpace.includes(to.path)) {
			next('/login')
		} else {
			next()
		}
	}
})
export default router