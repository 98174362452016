// beDateTime 转换时间工具方法
function dateTimeAdjust(someday) {
	var date = new Date(new Date(someday).getTime() - 8 * 60 * 60 * 1000);//8小时之差
	var year = date.getFullYear();
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
	var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	// 拼接
	return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

// 获取日期时间值
function getDateTimeNum(someday) {
	let toDate = new Date(someday);
	let b = new Array(6);
	b[0] = toDate.getFullYear();
	b[1] = toDate.getMonth() + 1;
	b[2] = toDate.getDate();
	b[3] = toDate.getHours();
	b[4] = toDate.getMinutes();
	b[5] = toDate.getSeconds();
	return b
}
//标准时间转换 
function formatGMT(time) {
	var date = new Date(time);
	var year = date.getFullYear();
	/* 在日期格式中，月份是从0开始的，因此要加0
	 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
	 * */
	var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
	var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
	var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	// 拼接
	return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}
//日期格式化
function dateFormat(someday) {
	let b = getDateTimeNum(someday);
	if (b[1] < 10) {
		b[1] = "0" + b[1]
	}
	if (b[2] < 10) {
		b[2] = "0" + b[2]
	}
	let ymd = b[0] + "-" + b[1] + "-" + b[2];
	return ymd
}

// 获取日期时间值
function getDateTimeNumCutT(someday) {
	let val = someday.replace('T', ' ')
	return val
}
//时间格式化
function timeFormat(someday) {
	let b = getDateTimeNum(someday);
	for (let i = 3; i < 6; i++) {
		if (b[i] < 10) {
			b[i] = "0" + b[i]
		}
	}
	let hms = b[3] + ":" + b[4] + ":" + b[5];
	return hms
}

// 时间截取，当天显示时分秒，其它显示年月日
function subDate(someday) {
	if (someday == '0001-01-01T00:00:00') {
		return '';
	}
	let a = getDateTimeNum(new Date());
	let b = getDateTimeNum(someday);
	let sameday = (a[0] == b[0]) && (a[1] == b[1]) && (a[2] == b[2]);
	if (sameday) {
		return "今天 " + timeFormat(someday)
	} else {
		return dateFormat(someday)
	}
}

// 根据年月日时分秒数组获取时间戳
function getTimeStamp(b) {
	for (let i = 0; i < b.length; i++) {
		b[i] = parseInt(b[i])
	}
	let someDay = [b[0], b[1], b[2]];
	let someTime = [b[3], b[4], b[5]];
	let t = new Array(2);
	t[0] = someDay.join('-');
	t[1] = someTime.join(':');
	let timeStamp = new Date(t.join(' ')).getTime();
	return timeStamp
}

// 计算年龄
function getAge(birthday, currentDay) {
	let a = getDateTimeNum(currentDay);
	let b = getDateTimeNum(birthday);
	let age = 0
	if (a[1] > b[1] || (a[1] = b[1] && a[2] >= b[2])) {
		age = a[0] - b[0]
	} else {
		age = a[0] - b[0] - 1
	}
	return age
}

// 性别字符转换
function getGender(str) {
	if (str == "0") {
		return "女"
	} else {
		return "男"
	}
}

// 格式化空字符
function FormatEmpty(str) {
	if (str == null || str == '') {
		return '暂未绑定'
	} else {
		return str
	}
}

// 保留两位小数点
function formatDecimal(value) {
	return parseFloat(value).toFixed(2);
}
// 设计富文本编辑器的字体和大小选择
function setEditor(Quill, Option) {
	//quill编辑器的字体
	let fontFamily = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman',
		'sans-serif'
	];
	let Font = Quill.import('formats/font');
	Font.whitelist = fontFamily; //将字体加入到白名单 
	Quill.register(Font, true);

	let fontSize = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px'];
	let fontSizeStyle = Quill.import("attributors/style/size");
	fontSizeStyle.whitelist = fontSize;
	Quill.register(fontSizeStyle, true);
	Option.modules.toolbar[0].size = fontSize;
	Option.modules.toolbar[1].font = fontFamily;
}
// 秒数转换为时分秒
function formatSeconds(value) {
	var theTime = parseInt(value);// 秒
	var theTime1 = 0;// 分
	var theTime2 = 0;// 小时
	if (theTime > 60) {
		theTime1 = parseInt(theTime / 60);
		theTime = parseInt(theTime % 60);
		if (theTime1 > 60) {
			theTime2 = parseInt(theTime1 / 60);
			theTime1 = parseInt(theTime1 % 60);
		}
	}
	var result = "" + parseInt(theTime);
	if (result < 10) {
		result = '0' + result;
	}
	if (theTime1 > 0) {
		result = "" + parseInt(theTime1) + ":" + result;
		if (theTime1 < 10) {
			result = '0' + result;
		}
	} else {
		result = '00:' + result;
	}
	if (theTime2 > 0) {
		result = "" + parseInt(theTime2) + ":" + result;
		if (theTime2 < 10) {
			result = '0' + result;
		}
	} else {
		result = '00:' + result;
	}
	return result;
}

function setConsultType(value) {
	if (value == 1) {
		return "支持"
	}
	else {
		return "不支持"
	}
}

function returnStatus(code) {
	if (code == 1) {
		return true
	} else {
		return false
	}
}


export default {
	getDateTimeNum,
	dateFormat,
	timeFormat,
	subDate,
	getTimeStamp,
	getAge,
	getGender,
	setEditor,
	FormatEmpty,
	formatDecimal,
	formatSeconds,
	setConsultType,
	returnStatus,
	formatGMT,
	dateTimeAdjust,
	getDateTimeNumCutT
}
